require('../../../js/bootstrap');

// nav class change

$('.navbar-collapse').on('shown.bs.collapse', function () {

  $('body').addClass('slide-out');
})

$('.navbar-collapse').on('hidden.bs.collapse', function () {
 
  $('body').removeClass('slide-out');
 
})


// hide nav when page is clicked

$(function() {
  $(document).click(function (event) {
    $('.navbar-collapse').collapse('hide');
  });
});